<template>
  <svg width="320" height="170" viewBox="0 0 320 170" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse opacity="0.08" cx="160.467" cy="135.34" rx="151.467" ry="23.4667" fill="#B4B4DF" />
    <g filter="url(#filter0_dd_817_8570)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M60.5121 61.7997C75.5026 53.2351 107.378 40.5295 126.808 33.5686C126.93 33.5248 127.055 33.5373 127.163 33.6117C135.426 39.2925 151.447 55.783 166.718 73.8285C164.927 85.0254 170.592 97.9273 182.073 106.269C185.619 108.845 189.407 110.759 193.247 112.022C190.37 113.236 187.487 114.404 184.569 115.587C169.88 121.539 154.307 127.851 134.054 142.031C133.866 142.163 133.598 142.113 133.472 141.918C111.941 108.534 81.3835 78.3948 60.4806 62.4435C60.2532 62.27 60.2675 61.9395 60.5121 61.7997Z" fill="url(#paint0_linear_817_8570)" />
    </g>
    <path opacity="0.8" d="M95.5366 61.5357C93.457 62.376 90.4094 64.7325 88.4671 66.3818C87.9489 66.8218 88.029 67.6368 88.6071 67.9879C91.4612 69.7212 94.3966 71.7605 98.1659 75.0962C98.5969 75.4776 99.2587 75.4175 99.6375 74.9801C101.624 72.686 103.365 71.7189 105.267 70.8238C105.753 70.595 105.983 70.0234 105.722 69.553C103.807 66.1018 98.8079 62.1378 96.0276 61.4938C95.8636 61.4558 95.6932 61.4724 95.5366 61.5357Z" fill="#37364D" />
    <path d="M110 86.1596C114 80.4999 134.5 70.9999 140.5 68.9999" stroke="#37364D" stroke-width="4" stroke-linecap="round" stroke-dasharray="8 8" />
    <path d="M120.354 100.888C126 95 152.5 82 160.115 78.4851" stroke="#37364D" stroke-width="4" stroke-linecap="round" stroke-dasharray="8 8" />
    <path d="M131.027 116.485C138 110.5 166 96.5 175.696 94.2392" stroke="#37364D" stroke-width="4" stroke-linecap="round" stroke-dasharray="8 8" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M225.315 104.881C234.389 92.3912 229.396 73.2941 214.163 62.2263C198.929 51.1585 179.224 52.311 170.15 64.8006C161.075 77.2902 166.068 96.3873 181.302 107.455C196.535 118.523 216.241 117.37 225.315 104.881ZM219.243 103.872C226.461 93.937 221.957 78.3599 209.184 69.0794C196.41 59.7989 180.204 60.3294 172.986 70.2643C165.768 80.1992 170.271 95.7764 183.045 105.057C195.818 114.337 212.024 113.807 219.243 103.872Z" fill="#474667" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M224.764 105.35C215.204 116.265 196.604 116.827 182.073 106.269C167.543 95.7124 162.329 77.8513 169.752 65.3862C169.29 65.9138 168.849 66.4655 168.431 67.0414C159.357 79.5309 164.35 98.628 179.583 109.696C194.817 120.764 214.522 119.611 223.596 107.122C224.015 106.545 224.405 105.954 224.764 105.35Z" fill="#474667" />
    <path d="M212.876 63.8713C227.097 74.2039 230.829 91.2112 223.254 101.637C215.679 112.063 198.351 113.77 184.13 103.437C169.908 93.1045 166.177 76.0972 173.752 65.6714C181.326 55.2455 198.654 53.5388 212.876 63.8713Z" stroke="#676585" stroke-width="7" />
    <path d="M183.01 80.936C183.383 84.9797 185.495 89.2878 189.152 92.6986C193.962 97.1839 200.133 98.8936 205 97.5757" stroke="#676585" stroke-width="3.5" stroke-linecap="round" />
    <rect width="8.51731" height="34.0692" rx="4.25866" transform="matrix(0.587785 -0.809017 0.809016 0.587787 218.582 106.096)" fill="#676585" />
    <path d="M190.476 41.152C190.268 41.152 190.164 41.048 190.164 40.84L190.068 36.568C190.068 36.36 190.172 36.256 190.38 36.256H191.244C192.428 36.256 193.292 36.008 193.836 35.512C194.38 35 194.652 34.352 194.652 33.568C194.652 32.848 194.428 32.28 193.98 31.864C193.548 31.432 192.932 31.216 192.132 31.216C191.46 31.216 190.9 31.384 190.452 31.72C190.004 32.056 189.644 32.52 189.372 33.112C189.276 33.288 189.14 33.352 188.964 33.304L186.444 32.656C186.22 32.608 186.148 32.48 186.228 32.272C186.692 30.944 187.46 29.912 188.532 29.176C189.604 28.424 190.884 28.048 192.372 28.048C193.476 28.048 194.452 28.264 195.3 28.696C196.148 29.128 196.812 29.736 197.292 30.52C197.772 31.288 198.012 32.2 198.012 33.256C198.012 34.168 197.82 35.024 197.436 35.824C197.068 36.608 196.524 37.272 195.804 37.816C195.084 38.36 194.204 38.72 193.164 38.896L193.116 40.84C193.116 41.048 193.012 41.152 192.804 41.152H190.476ZM191.628 46.24C191.084 46.24 190.628 46.072 190.26 45.736C189.892 45.384 189.708 44.936 189.708 44.392C189.708 43.832 189.892 43.392 190.26 43.072C190.628 42.736 191.084 42.568 191.628 42.568C192.172 42.568 192.628 42.736 192.996 43.072C193.364 43.392 193.548 43.832 193.548 44.392C193.548 44.936 193.364 45.384 192.996 45.736C192.628 46.072 192.172 46.24 191.628 46.24Z" fill="#686788" />
    <path d="M174.904 47.3382C174.757 47.4853 174.61 47.4853 174.463 47.3382L171.374 44.3854C171.227 44.2383 171.227 44.0912 171.374 43.9441L171.985 43.3332C172.822 42.496 173.258 41.7097 173.292 40.9743C173.315 40.2276 173.049 39.577 172.494 39.0227C171.985 38.5135 171.425 38.2703 170.814 38.2929C170.203 38.2929 169.615 38.5758 169.049 39.1414C168.574 39.6166 168.297 40.1314 168.218 40.6858C168.139 41.2401 168.212 41.8228 168.438 42.4337C168.495 42.6261 168.444 42.7675 168.286 42.858L166.046 44.1817C165.853 44.3062 165.712 44.2666 165.621 44.0629C165.01 42.7958 164.824 41.523 165.061 40.2445C165.288 38.9548 165.927 37.7838 166.979 36.7316C167.76 35.951 168.602 35.4136 169.508 35.1194C170.413 34.8253 171.312 34.7857 172.206 35.0006C173.088 35.2043 173.903 35.6795 174.65 36.4262C175.294 37.071 175.764 37.8121 176.058 38.6493C176.352 39.4639 176.437 40.3181 176.313 41.2119C176.188 42.1056 175.821 42.9825 175.21 43.8423L176.55 45.2509C176.697 45.3979 176.697 45.545 176.55 45.6921L174.904 47.3382ZM179.317 50.1214C178.932 50.5061 178.491 50.7097 177.993 50.7323C177.484 50.7437 177.037 50.557 176.652 50.1723C176.256 49.7763 176.075 49.3351 176.109 48.8486C176.132 48.3508 176.335 47.9096 176.72 47.5249C177.105 47.1402 177.546 46.9366 178.044 46.914C178.53 46.88 178.971 47.061 179.367 47.457C179.752 47.8417 179.939 48.2886 179.927 48.7977C179.905 49.2955 179.701 49.7367 179.317 50.1214Z" fill="#686788" />
    <path d="M206.953 45.1947C206.806 45.0476 206.806 44.9005 206.953 44.7535L209.906 41.6648C210.053 41.5177 210.2 41.5177 210.347 41.6648L210.958 42.2758C211.795 43.113 212.581 43.5485 213.317 43.5825C214.063 43.6051 214.714 43.3392 215.268 42.7849C215.777 42.2758 216.021 41.7157 215.998 41.1048C215.998 40.4938 215.715 39.9055 215.15 39.3398C214.674 38.8647 214.16 38.5875 213.605 38.5083C213.051 38.4291 212.468 38.5026 211.857 38.7289C211.665 38.7855 211.524 38.7346 211.433 38.5762L210.109 36.3361C209.985 36.1437 210.024 36.0023 210.228 35.9118C211.495 35.3009 212.768 35.1142 214.046 35.3518C215.336 35.578 216.507 36.2173 217.559 37.2694C218.34 38.0501 218.877 38.893 219.172 39.7981C219.466 40.7031 219.505 41.6026 219.29 42.4964C219.087 43.3788 218.612 44.1934 217.865 44.9401C217.22 45.585 216.479 46.0545 215.642 46.3487C214.827 46.6428 213.973 46.7277 213.079 46.6032C212.185 46.4788 211.309 46.1111 210.449 45.5002L209.04 46.8408C208.893 46.9879 208.746 46.9879 208.599 46.8408L206.953 45.1947ZM204.17 49.607C203.785 49.2224 203.581 48.7811 203.559 48.2833C203.547 47.7742 203.734 47.3273 204.119 46.9427C204.515 46.5467 204.956 46.3657 205.442 46.3996C205.94 46.4222 206.381 46.6259 206.766 47.0105C207.151 47.3952 207.354 47.8364 207.377 48.3342C207.411 48.8207 207.23 49.262 206.834 49.6579C206.449 50.0426 206.002 50.2293 205.493 50.218C204.996 50.1954 204.554 49.9917 204.17 49.607Z" fill="#686788" />
    <defs>
      <filter id="filter0_dd_817_8570" x="59.5188" y="33.5435" width="133.729" height="111.361" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-0.8" dy="2.8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.362962 0 0 0 0 0.358438 0 0 0 0 0.4625 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_817_8570" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-0.4" dy="1.6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.304715 0 0 0 0 0.30191 0 0 0 0 0.391667 0 0 0 1 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_817_8570" result="effect2_dropShadow_817_8570" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_817_8570" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_817_8570" x1="185.273" y1="140.032" x2="89.0807" y2="58.9427" gradientUnits="userSpaceOnUse">
        <stop stop-color="#545373" />
        <stop offset="1" stop-color="#656382" />
      </linearGradient>
    </defs>
  </svg>
</template>
